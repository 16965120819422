$primary: #01397d;
$secondary: #00a3e0;
$success: #28a745;
$danger: #dc3545;

@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-balham.css";
@import "~bootstrap/scss/bootstrap";

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";

// @import 'https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@200&display=swap';
@import "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback";

@import "../node_modules/rc-steps/assets/index.css";

body {
  margin: 0;
  font-family: "Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
}

*, html {
  scroll-behavior: smooth !important;
}

.ContainerMessage {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
}

.ContainerMessage2 {
  height: 100%;
  width: 100%;
  display: none;
}

.custom-select, .accordion-button:focus { 
  z-index: auto !important;
}

.custom-select >div {
  border: 0.5px solid #dee2e6 !important;
}

.input-group .btn {
  z-index: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.float-custom-button {
  bottom: 20px;
  right: 20px;
  background: white;
  padding: 10px;
  border-radius: 50%;
  color: white;
  background-color: #e4c01b;
}