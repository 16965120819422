.ViewRDIListar{

    .btn-accion{
        border-radius: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .reload {
        bottom: 20px;
        right: 20px;
        background: white;
        padding: 10px;
        border-radius: 50%;
        color: white;
    }

    .search {
        bottom: 20px;
        right: 70px;
        background: white;
        padding: 10px;
        border-radius: 50%;
        color: white;
    }

    .data-empty {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0%);
    }

    .pending {
        background-color: #fde5e5;
    }

    .levantamiento {
        background-color: #f8f1cb;
    }
}