.ElementStepper {
    .stepper-item {
        min-width: 80px;
        //width: -webkit-fill-available;
    }
    .stepper-item-finally {
        min-width: 35px;
        //width: -webkit-fill-available;
    }
    .line, .line-current, .line-active {
      height: 6px;
      background-color: lightgrey !important;
    }
    .line-active {
        background-color: #01397d  !important;
    }
    .stepCircle {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        width: 100px;
        max-width: 35px;
    }
    .circle-incomplete {
        background-color: lightgrey !important;
    }
    .stepperDescription {
        transform: translateX(-27%);
        width: 75px;
        label {
            line-height: normal;
            text-align: center;
            font-size: 11px;
        }
    }
    .lead {
      font-size: 1.1rem;
    }
}
  