.ViewAutochequeoItem {
    min-height: 210px;

    .group-content-item {
        width: 100%;
        padding: 5px;
        margin: 0; 
        border-radius: 10px; 
        cursor: pointer;
    }
    
    div span:nth-child(1) {
        min-width: max-content;
    }

    span {
        font-size: 13px;
    }
    
    .pending {
        background-color: #fde5e5;
    }
}

.atributos{
    margin-left: 1px !important; 
}
.options{
    margin-top: -10px !important; 
    position:static;
    margin-right: 0px;
    
}
.subItems{
    width: 100%;
    padding: 5px;
    margin: 1px; 
    border-radius: 10px; 
    cursor: pointer;
    margin-top: 5px !important; 
}

.subItemsOption{
    margin-left: -20px !important;
    position:static; 
    margin-right: 0px;
}


.mt-0 {
    margin-top: 24px !important;
    padding-right: 5px;
    margin-left: -10px !important;
}
.tbl-margin {
    margin-top: -25px !important;
}
.cc-margin {
    margin-top: -20px !important; 
}
.enviar-email{
    margin-top: -7px !important; 
}

.icoVerCP{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #e7e1e1;
}

.correctoCP{
    background-color: #dddada;
}
.pendienteCP{
    background-color: #e8dded;
}

.icon-circle-base {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pending-cp {
    background: #fff2cf;
}