.ViewRDIForm {
  .container-form {
    padding: 10px 20px 100px;

    .form-row {
      justify-content: space-between;
      margin-bottom: 15px;
      .form-group {
        width: 47%;
      }
    }

    .detail {
      // margin-top: 4px;
      border-radius: 12px;
      // padding: 20px 15px 5px;

      .camera {
        margin-right: 15px;
      }
    }

    .group-button {
      margin-top: 40px;
      justify-content: space-between;

      button {
        min-width: 150px;
      }
    }
  }
}
