.ViewMasterHome {
    .page-content {
        margin-top: 30px;
        margin-bottom: 60px;
        padding: 1rem;
    }

    .btn-icon-first {
        min-width: 46px;
    }

    .btn-change-country {
        bottom: 20px;
        right: 20px;
        background: white;
        padding: 10px;
        border-radius: 50%;
        color: white;
    }
}
