.ViewInspeccionItem {
    .group-content-item {
        width: 100%;
        padding: 5px;
        margin: 0; 
        border-radius: 10px; 
        cursor: pointer;
    }
    
    div span:nth-child(1) {
        min-width: max-content;
    }

    span {
        font-size: 13px;
    }
    
    .pending {
        background-color: #fde5e5;
    }

    .anomaly{
        background-color: #f8f1cb;
    }
}
.mt-0 {
    margin-top: 24px !important;
    padding-right: 5px;
    margin-left: -10px !important;
}
.tbl-margin {
    margin-top: -25px !important;
}
.cc-margin {
    margin-top: -20px !important; 
}
.enviar-email{
    margin-top: -7px !important; 
}