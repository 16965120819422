.ElementViewImagesCostume {
  .main-image {
    position: relative;
    button {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }

    img {
      opacity: 1;
      transition: 1s;
      min-height: 330px;
      min-width: 100%;
    }
    /*.main-image--loaded {
      opacity: 1;
    }*/
  }

  .group-image {
    background: #0000008c;
    margin-top: 25px;
    width: 100%;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    overflow-x: scroll;

    img {
      margin-right: 8px;
      border: 2px solid #fff;
    }

    .img-selected {
      border: 2px solid #5dcbff;
    }
  }
  .image-empty {
    min-height: 400px;
  }

  .group-image::-webkit-scrollbar {
    display: none;
  }
  img {
    object-fit: cover;
    border-radius: 10px;
  }
}