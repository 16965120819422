@keyframes shimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

.skeleton {
    border-radius: 4px;
    background: linear-gradient(90deg, #e0e0e0 25%, #c0c0c0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1s infinite;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); /* Sombra similar a la del ejemplo original */
}
