.ViewAutochequeoList {
    .container-fluid {
        padding: 0 1.5rem 4rem;
    }
    
    .ElementEmptyList {
        h4 {
            text-align: center;
            //font-size: 13px;
        }
    }

    .StepperCustomStyle {
        overflow: scroll;
        padding-bottom: 2.25rem !important;
        //>div:nth-child(1) {
            //margin-left: 5rem;
        //}
    }

    .radio_custom {
        accent-color: #01397d;
    }

    .btn-accion{
        border-radius: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .camera {
        margin-right: 15px;
    }
    
    .reload {
        bottom: 20px;
        right: 20px;
        background: white;
        padding: 10px;
        border-radius: 50%;
        color: white;
    }
    .search {
        bottom: 20px;
        right: 70px;
        background: white;
        padding: 10px;
        border-radius: 50%;
        color: white;
    }
    .topInput{
        margin-top: 5px;
    }

    .data-empty {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0%);
    }

}

