.ViewRDIModal {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000000ad;
  z-index: 9999999999;
  display: flex;
  align-items: center;
  justify-content: center;

  .container-modal {
    min-height: 500px;
    width: 90%;
    background: white;
    border-radius: 12px;
  }

  .header {
    padding: 15px;
    border-bottom: 1px solid black;
    p {
      margin-left: auto;
      cursor: pointer;
    }
  }

  .body {
    height: 100%;
    min-height: 450px;
    padding: 15px;

    .main-image {
      position: relative;
      button {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
      }

      img {
        opacity: 1;
        transition: 1s;
      }
      /*.main-image--loaded {
        opacity: 1;
      }*/
    }

    .group-image {
      background: #0000008c;
      margin-top: 25px;
      width: 100%;
      border-radius: 10px;
      padding: 8px;
      display: flex;
      overflow-x: scroll;

      img {
        margin-right: 8px;
        border: 2px solid #fff;
      }

      .img-selected {
        border: 2px solid #5dcbff;
      }
    }
    .image-empty {
      min-height: 400px;
    }

    .group-image::-webkit-scrollbar {
      display: none;
    }
    img {
      object-fit: cover;
      border-radius: 10px;
    }
  }
}
