.divPaginado {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;

    p {
        margin: 0
    }
}

.react-pdf__Page {
    width: 100%;
}

.react-pdf__Page__canvas {
    border: 3px solid black;
}

.react-pdf__Page__canvas {
    width: 100% !important;
    height: 400px !important;
}

.react-pdf__Page__textContent {
    display: none !important;
}

.react-pdf__Page__annotations {
    display: none !important;
}