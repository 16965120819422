.ViewAutochequeoForm {
    .container-fluid {
        padding: 0 1.5rem 4rem;
    }
    
    .ElementEmptyList {
        h4 {
            text-align: center;
            font-size: 13px;
        }
    }

    .StepperCustomStyle {
        overflow: scroll;
        padding-bottom: 2.25rem !important;
        //>div:nth-child(1) {
            //margin-left: 5rem;
        //}
    }

    .radio_custom {
        accent-color: #01397d;
    }
    
    .camera {
        margin-right: 15px;
    }

    .ItemRiesgos {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        >div {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

        }
        
        i {
            color: var(--bs-primary);
        }
        button {
            width: 60px;
        }
    }

    canvas {
        border: 1px solid black;
    }
}