.ViewNotification {

    .container-fluid {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }

    .ContainerCheck {
        display: flex;
        justify-content: end;

        >div {
            margin-bottom: 0 !important;
            >div {
                gap: 12px;
            }
        }
    }

    .container-item-notification {
        width: 100%;
        background-color: white;
        border-radius: 10px;

        border-left: #E86060 solid 4px;
        height: 90px;
        position: relative;

        i {
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
        }

        .badget-new {
            position: absolute;
            right: 12px;
            bottom: 8px;
            background-color: #E86060;
            color: white;
            width: 70px;
            border-radius: 6px;
            text-align: center;
            font-size: 12px;
        }

        .text-time {
            position: absolute;
            left: 12px;
            bottom: 4px;
            font-size: 12px;
        }

        .content-text {
            display: flex;
            flex-direction: column;
            width: 80%;
            height: 100%;
            padding-top: 10px;
            gap: 10px;

            .title {
                color: #01397D;
            }

            span {
                font-size: 12px;
                line-height: 12px;
            }
        }
    }

    .container-item-notification-readed {
        border-left: #818181 solid 4px;
    }

    .reload {
        bottom: 20px;
        right: 20px;
        background: white;
        padding: 10px;
        border-radius: 50%;
        color: white;
    }
}