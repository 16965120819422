.ViewInspeccionForm {
  .container-fluid {
    padding: 0 1.5rem 4rem;
  }

  .ElementEmptyList {
    h4 {
      text-align: center;
      font-size: 13px;
    }
  }
}

.container-levantamiento, .container-item-inspeccion {
  .header {
      background: #2b3139;
      color: white;

      .icon-menu {
          right: 20px;
      }
  }

  .content {
      span {
          font-size: 13px;
      }    
      div span:nth-child(1) {
          min-width: max-content;
      }
  }
}