.ViewInspeccionListar {

    .GroupFiltersAdmin {

        .Line {
            width: 100%;
            border: 1px solid #e3e3e3;
            margin-bottom: 10px;
        }
    }

    .container-fluid {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }

    .btn-accion{
        border-radius: 20px;
        padding-left: 20px;
        padding-right: 20px;
        right: 0.75rem;
        top: -0.3rem;
    }
    
    .reload {
        bottom: 20px;
        right: 20px;
        background: white;
        padding: 10px;
        border-radius: 50%;
        color: white;
    }

    .search {
        bottom: 20px;
        right: 70px;
        background: white;
        padding: 10px;
        border-radius: 50%;
        color: white;
    }
}