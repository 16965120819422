.ViewVariable {
    padding: 0 12px;

    .btn-accion {
        border-radius: 20px;
        padding-left: 20px;
        padding-right: 20px;
        right: 0.75rem;
        top: -0.3rem;
    }

    .GroupListModule {
        min-height: 50px;
        width: 100%;
        padding: 10px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background-color: white;
        flex-direction: column;

        .HeaderGroup {
            width: 100%;
            
            .ContentHeader {
                min-height: 40px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
        
        span {
            font-size: 0.8rem;
        }
        
        .BodyGroup {
            margin-top: 60px;
            width: 90%;
            position: relative;

            button {
                position: absolute;
                top: -40px;
                right: 0;
            }
        }
    }
}
